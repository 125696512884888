import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appQuantityMask][ngModel]',
})
export class QuantityMaskDirective implements OnInit, OnChanges {
    constructor(private el: ElementRef, private control: NgControl) {}

    ngOnInit() {
        setTimeout(() => {
            if (this.control.value) {
                this.control.valueAccessor.writeValue(
                    this.formatCurrency(this.control.value)
                );
            }
        }, 100);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['ngModel'] && this.control.control) {
          const formattedValue = this.formatCurrency(changes['ngModel'].currentValue);
          this.control.control.setValue(formattedValue);
        }
    }

    @HostListener('input', ['$event'])
    onInputChange(event: Event) {
        const input = event.target as HTMLInputElement;
        let value = input.value.replace(/\D/g, '');
        value = (parseFloat(value) / 100).toFixed(2);
        const formattedValue = this.formatCurrency(value);
        input.value = formattedValue;

        this.control.viewToModelUpdate(value);
    }

    private formatCurrency(value: string): string {
        value = value.toString();
        let parts = value.split('.');
        parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '1.');
        if (parts[0] === 'NaN') {
            parts[0] = '0';
        }
        return `${parts.join(',')}`;
    }
}
